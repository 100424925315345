import(/* webpackMode: "eager" */ "/usr/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/usr/app/public/logo.webp");
;
import(/* webpackMode: "eager" */ "/usr/app/src/app/[locale]/(dashboard)/layout.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/app/src/components/Header/index.tsx");
;
import(/* webpackMode: "eager" */ "/usr/app/src/components/LeftMenu/index.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/app/src/components/LeftMenu/MenuButton.tsx");
